<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Yeni Firma Ekle'"
    :total="rows.length"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
  >
    <template v-slot:leftBody>
      <ul class="list-group">
        <CompanyItem v-for="(item, index) in rows" :key="index" :item="item" @handlerUpdate="handlerUpdate($event)" />
      </ul>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <CompanyAdd v-else :updateData="updateData" @show="show = $event" @handlerClose="type = $event" />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import CompanyItem from './component/CompanyItem.vue';
import CompanyAdd from './component/CompanyAdd.vue';
import { ref, watch, defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
export default defineComponent({
  components: {
    BListCard,
    CompanyItem,
    CompanyAdd,
  },
  setup() {
    const expo = {};
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.rows = computed(() => store.getters.getFirmalar);

    store.dispatch('firmaListele');

    expo.companyList = ref([
      {
        k_no: 123,
        baslik: 'Demo 1',
      },
      {
        k_no: 123,
        baslik: 'Demo 2',
      },
      {
        k_no: 123,
        baslik: 'Demo 3',
      },
      {
        k_no: 123,
        baslik: 'Demo 4',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
      {
        k_no: 123,
        baslik: 'Demo 5',
      },
    ]);

    expo.handlerNewItem = () => {
      expo.updateData.value = {};
      expo.type.value = true;
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    watch(expo.search, (val) => {});

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
