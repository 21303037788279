<template>
  <li class="list-group-item">
    <div class="ana-item">
      <div>
        <i class="fad fa-sort-up move-el"></i>
        <span>{{ item.firma_adi }}</span>
      </div>
      <b-button-group>
        <b-button size="sm" variant="warning" tooltip="düzenle" class="rounded-0" @click="$emit('handlerUpdate', item)">
          <i class="fad fa-edit"></i>
        </b-button>
        <b-button size="sm" variant="danger" class="rounded-0" @click="handlerRemove(item.k_no)">
          <i class="fad fa-trash"></i>
        </b-button>
      </b-button-group>
    </div>
  </li>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { defineComponent } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  props: {
    item: {
      type: Object,
      requied: true,
    },
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Firmaya Ait Tüm Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('firmaSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
