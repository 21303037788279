<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <validation-provider name="Firma Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Firma Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                id="firma_adi"
                name="firma_adi"
                ref="firma_adi"
                v-model="form.firma_adi"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="E mail" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="E mail">
              <b-form-input
                size="lg"
                class="rounded-0"
                id="e_mail"
                name="e_mail"
                ref="e_mail"
                v-model="form.e_mail"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Telefon">
              <b-form-input
                size="lg"
                class="rounded-0"
                id="telefon"
                name="telefon"
                ref="telefon"
                v-model="form.telefon"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Adres">
            <b-form-textarea id="adres" v-model="form.adres" class="rounded-0" rows="2" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center">
            <label class="mt-2">Domain Oluştur</label>
            <b-form-checkbox :checked="form.domain_create" v-model="form.domain_create" switch size="lg">
              <span class="switch-icon-left">
                <i class="fad fa-bell"></i>
              </span>
              <span class="switch-icon-right">
                <i class="fad fa-bell-slash"></i>
              </span>
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex justify-content-between align-items-center">
            <label class="mt-2">SSL Oluştur</label>
            <b-form-checkbox :checked="form.ssl_create" v-model="form.ssl_create" switch size="lg">
              <span class="switch-icon-left">
                <i class="fad fa-bell"></i>
              </span>
              <span class="switch-icon-right">
                <i class="fad fa-bell-slash"></i>
              </span>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="form.domain_create">
        <b-col cols="12">
          <validation-provider name="Domain" :rules="{ required: form.domain_create }" v-slot="validationContext">
            <b-form-group label="Domain">
              <b-form-input
                size="lg"
                class="rounded-0"
                id="domain"
                name="domain"
                ref="domain"
                v-model="form.domain"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-provider name="Database Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Database Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                id="db"
                name="db"
                ref="db"
                v-model="form.db"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Lisans Başlama Tarihi">
            <flat-pickr
              v-model="start_date"
              class="form-control form-control-lg rounded-0 bg-white"
              :config="config"
              :events="config.events"
              @on-change="handlerOnChangeDate('baslama', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Lisans Bitiş Tarihi">
            <flat-pickr
              v-model="end_date"
              class="form-control form-control-lg rounded-0 bg-white"
              :config="config"
              :events="config.events"
              @on-change="handlerOnChangeDate('bitis', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Firma Notu ( Acıklama )">
            <b-form-textarea v-model="form.aciklama" class="rounded-0" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Statü">
            <v-select
              v-model="form.statu"
              :options="statuTitle"
              :reduce="(statu) => statu.id"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex justify-content-end">
            <b-button squared size="lg" variant="secondary" @click="$emit('handlerClose', false)">
              <i class="fad fa-times-square"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import store from '@/store';
import moment from 'moment';
export default defineComponent({
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    const { updateData } = toRefs(props);

    expo.start_date = ref(moment().format('DD.MM.YYYY'));
    expo.end_date = ref(moment().format('DD.MM.YYYY'));
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      // minDate: 'today',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    });

    expo.statuTitle = ref([
      {
        id: 1,
        title: 'Aktif',
      },
      {
        id: 2,
        title: 'Pasif',
      },
      {
        id: 3,
        title: 'Bakım Modu',
      },
    ]);

    expo.form = ref({
      k_no: null,
      firma_adi: null,
      domain: null,
      domain_create: false,
      ssl_create: false,
      e_mail: null,
      telefon: null,
      adres: null,
      kur_turu: 'TRY',
      db: null,
      baslama_tarih: moment().format('YYYY-MM-DD'),
      bitis_tarih: moment().format('YYYY-MM-DD'),
      statu: 3,
    });

    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        firma_adi: null,
        domain: null,
        domain_create: false,
        ssl_create: false,
        e_mail: null,
        telefon: null,
        adres: null,
        kur_turu: 'TRY',
        db: null,
        baslama_tarih: moment().format('YYYY-MM-DD'),
        bitis_tarih: moment().format('YYYY-MM-DD'),
        statu: 3,
      };
    };

    expo.handlerOnChangeDate = (type, event) => {
      if (event) {
        if (type == 'baslama') {
          expo.form.value.baslama_tarih = moment(String(event)).format('YYYY-MM-DD');
        } else {
          expo.form.value.bitis_tarih = moment(String(event)).format('YYYY-MM-DD');
        }
      }
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      // context.emit('show', true);
      const basrUrl = expo.form.value.k_no == null ? 'firmaEkle' : 'firmaGuncelle';
      store.dispatch(basrUrl, expo.form.value).then((res) => {
        if (res.data.success === true) {
          toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
          // context.emit('show', false);
          if (expo.form.value.k_no == null) {
            expo.resetForm();
          }
        }
      });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = {
            ...val,
            baslama_tarih: moment(val.baslama_tarih).format('YYYY-MM-DD'),
            bitis_tarih: moment(val.bitis_tarih).format('YYYY-MM-DD'),
          };

          expo.start_date.value = moment(val.baslama_tarih).format('DD.MM.YYYY');
          expo.end_date.value = moment(val.bitis_tarih).format('DD.MM.YYYY');
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
